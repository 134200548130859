import React from "react";
import Box from "./Box";
import { useState } from "react";
const Data = ({ className, oldList }) => {
  const list = [oldList[0]];
  const [count, setCount] = useState(0);
  const [item, setItem] = useState(list);
  function handlePrevious() {
    list.pop();
    list.push(oldList[count - 1]);
    setItem(list);
    setCount(count - 1);
  }
  function handleNext() {
    list.pop();
    list.push(oldList[count + 1]);
    setItem(list);
    setCount(count + 1);
  }
  // function handleKey(e) {
  //   if (e.key === "Enter ") {
  //     e.preventDefault();
  //     handleNext();
  //   }
  // }
  let prev = count <= 0 ? "display" : "";
  let next = count <= oldList.length - 2 ? "" : "display";
  const nextButtonLabel = count < oldList.length - 2 ? "Next" : "Submit";
  const [formData, setFormData] = useState({
    Question11: "",
    Question22: "",
    Question33: "",
    Question44: "",
    Question55: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const Appscript_URL = process.env.REACT_APP_SCRIPT_URL;
  // console.log(process.env);
  // console.log(Appscript_URL);
  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (nextButtonLabel === "Submit") {
      // const formEle = document.querySelector("form");
      // const formDatab = new FormData(formEle);
      // const formvalue = formData;
      const formDataEncoded = new URLSearchParams(formData).toString();
      fetch(Appscript_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formDataEncoded,
      })
        .then((res) => res.text())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && nextButtonLabel === "Submit") {
      // const formEle = document.querySelector("form");
      // const formDatab = new FormData(formEle);
      // const formvalue = formData;
      const formDataEncoded = new URLSearchParams(formData).toString();
      fetch(Appscript_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formDataEncoded,
      })
        .then((res) => res.text())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
      handleNext();
    }
    if (e.key === "Enter") {
      e.preventDefault();
      handleNext();
      // handleSubmit();
    }
  };
  // document.querySelectorAll("submit");
  // try {
  //   await writeToGoogleSheets(formData);
  //   alert("Data submitted successfully!");
  // } catch (error) {
  //   console.error("Error writing to Google Sheets", error);
  // }

  // const writeToGoogleSheets = async (formData) => {
  //   const spreadsheetId = "1wmYdf-famySaEISHm2-ZI4E1vXUBJFl5lCBKHLTp5Bg"; // Replace with your spreadsheet ID
  //   const sheetName = "Sheet1"; // Replace with your sheet name
  //   const accessToken = "YOUR_ACCESS_TOKEN"; // Obtain this through OAuth or Service Account flow

  //   const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1:append?valueInputOption=RAW`;

  //   const response = await fetch(url, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       values: [
  //         [formData.name, formData.email, formData.message], // Replace with your JSON fields
  //       ],
  //     }),
  //   });

  //   if (!response.ok) {
  //     throw new Error("Failed to write data to Google Sheets");
  //   }
  // };
  return (
    <form
      className="form"
      onSubmit={(e) => handleSubmit(e)}
      // onkeydown={handleKeyDown}
      onKeyDown={handleKeyDown}
    >
      <div className={className ? "Boxhide" : "Box"}>
        <div className="box1">
          {item.map((dicts) => (
            <Box array={dicts} key={dicts.id} handleOnChange={handleChange} />
          ))}
        </div>
        <div className="box-bottom">
          <button className={`previousbutton ${prev}`} onClick={handlePrevious}>
            <img alt="righticon" src="/Assets/lefticon.png"></img>
            previous
          </button>

          <button
            type="submit"
            className={`nextbutton ${next}`}
            onClick={handleNext}
          >
            {nextButtonLabel}
            <img alt="righticon" src="/Assets/rightarrow.png"></img>
          </button>
        </div>
      </div>
    </form>
  );
};

export default Data;
