import React from "react";
const Option = ({ tick, setTick }) => {
  function handleClick() {
    setTick("3");
    document.getElementById("tickinput993").checked = { tick };
  }
  function handleClick1() {
    setTick("2");
    document.getElementById("tickinput992").checked = { tick };
  }
  function handleClick2() {
    setTick("1");
    document.getElementById("tickinput991").checked = { tick };
  }

  return (
    <div>
      <div className="choiceheading">What best describes you ?</div>
      <div className="choose-option">
        <div className="answer" onClick={handleClick2}>
          <input
            className="tickinput"
            name="Start"
            id="tickinput991"
            type="checkbox"
            checked={tick === "1"}
            onChange={() => setTick("1")}
          ></input>
          <div className="answer-div2">
            <img className="choice-img" alt="img" src="/Assets/start.jpg"></img>
            <p>I Want to start my business !!</p>
          </div>
        </div>
        <div className="answer" onClick={handleClick1}>
          <input
            className="tickinput"
            name="Vision"
            id="tickinput992"
            type="checkbox"
            checked={tick === "2"}
            onChange={() => setTick("2")}
          ></input>
          <div className="answer-div2">
            <img
              className="choice-img"
              alt="img"
              src="/Assets/people.jpg"
            ></img>
            <p>I want people to know my vision!!</p>
          </div>
        </div>
        <div className="answer" onClick={handleClick}>
          <input
            className="tickinput"
            name="Investor"
            id="tickinput993"
            type="checkbox"
            checked={tick === "3"}
            onChange={() => setTick("3")}
          ></input>
          <div className="answer-div2">
            <img
              className="choice-img"
              alt="img"
              src="/Assets/flying-money.png"
            ></img>
            <p>I want to connect with Investors !!</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Option;
