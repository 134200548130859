import React from "react";
import Option from "./Option";
import { useState } from "react";
import Data from "./Data";
const Main = ({ classNamemain, setClassNamemain }) => {
  const common = [
    {
      id: "1",
      question: "what major problem are you solving for the world",
      answer: "giving content to founders",
      name: "Question11",
    },
    {
      id: "2",
      question: "Your Name",
      answer: "Akhil Gupta",
      name: "Question22",
    },
    {
      id: "3",
      question: " Your Email ID ?",
      answer: "thethoughtbistro@gmail.com",
      name: "Question33",
    },
    {
      id: "5",
      question: "Your Company Name ?",
      answer: "thethoughtbistro",
      name: "Question44",
    },
    {
      id: "6",
      question: "Your Phone Number ?",
      answer: "9876543210",
      name: "Question55",
    },
    {
      id: "4",
      thanks: "Upon selection my team will get in touch with you shortly. ",
      thanks1: "Thanks for taking the first steps to",
      thanks2: "-Getting Investors on board",
      thanks3: "-Establishing trust with your community",
      answer: "Youtube",
    },
  ];

  const dict = [
    {
      id: "1",
      question: "What important truth do very few people agree with you on ?",
      answer: "PS Can'r wait to have you on the podcast in the future",
      name: "Question11",
    },
    {
      id: "2",
      question: "Your good name please😊!!",
      answer: "Akhil Gupta",
      name: "Question22",
    },
    {
      id: "3",
      question: "What is your Emailaddress ?",
      answer: "thethoughtbistro@gmail.com",
      name: "Question33",
    },
    {
      id: "4",
      thanks: "Thank you for your details!!",
      thanks1:
        "Check out our podcast on youtube to learn more about others who have done what you are looking to do",
      answer: "youtube",
    },
  ];
  const [className, setClassName] = useState(true);
  const [className1, setClassName1] = useState(true);

  const [tick, setTick] = useState(0);

  function handleNextMain() {
    console.log(typeof tick);
    if (tick === "1") {
      setClassName(!className);
      setClassNamemain(!classNamemain);
    } else if (tick === "2" || tick === "3") {
      setClassName1(!className1);

      setClassNamemain(!classNamemain);
    }
  }

  return (
    <div className="Box-main">
      <div className={classNamemain ? "Box-option" : "Boxhide"}>
        <Option tick={tick} setTick={setTick} />
        <div className="box-bottom1">
          <button
            name="Name"
            type="submit"
            className="nextmain"
            onClick={handleNextMain}
          >
            Next <img alt="righticon" src="/Assets/rightarrow.png"></img>
          </button>
        </div>
      </div>

      <Data
        oldList={dict}
        className={className}
        // handleChange={handleChange}
        // handleSubmit={handleSubmit}
      />
      <Data
        oldList={common}
        className={className1}
        // handleChange={handleChange}
        // handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default Main;
