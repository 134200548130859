import React from "react";

const Box = ({ array, handleOnChange }) => {
  const valuename = array.name;
  return (
    <div>
      {array.id === "4" ? (
        <div className="questions">
          <p className="questions-heading">{array.thanks}</p>
          <p className="questions-heading">{array.thanks1}</p>

          <p className="questions-heading">{array.thanks2}</p>
          <p className="questions-heading">{array.thanks3}</p>
          <a
            href="https://www.youtube.com/@thoughtbistro"
            className="questions-heading"
          >
            {array.answer}
          </a>
        </div>
      ) : (
        <div className="questions">
          <p className="questions-heading">{array.question}</p>
          <input
            className="input"
            required
            type="text"
            name={valuename}
            placeholder={"example : " + array.answer}
            value={FormData.valuename}
            onChange={handleOnChange}
          />
          {console.log(FormData.valuename)}
        </div>
      )}
    </div>
  );
};

export default Box;
