import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer1">
        <div className="div-footer-logo">
          <img
            className="footer-logo"
            src="/Assets/footer-logo.png"
            alt="logo"
          ></img>
        </div>
        <p className="footer-privacy">© 2024, by Thethoughtbistro.com LLC</p>
      </div>
      <div className="footer2">
        <a href="https://www.youtube.com/@thoughtbistro">Youtube</a>
        <a href="https://open.spotify.com/show/2UMA4Vrn9quC1pBHzjgKUW?si=60b53c341ead4e0c">
          Spotify
        </a>
        <a href="https://www.instagram.com/thoughtbistro/">Instagram</a>
        <p>Careers</p>
        <p>Privacy policy</p>
        <p>Terms of service </p>
      </div>
    </div>
  );
};

export default Footer;
