import React from "react";

const Header = () => {
  return (
    <div className="header">
      <img className="header-logo" src="/Assets/Logo.png" alt="logo"></img>
    </div>
  );
};

export default Header;
