import React from "react";

const Landing = ({ classNamemain }) => {
  return (
    <div className="landing-bg">
      <div className="question">
        Do you want to
        <p className="scale">
          scale <span className="scale1">your</span>{" "}
          <span className="scale2">business?</span>
        </p>
      </div>
      <p className={classNamemain ? "choose" : "Boxhide"}>
        Click one of the three boxes below 👇
      </p>
    </div>
  );
};

export default Landing;
