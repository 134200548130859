import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Landing from "./Components/Landing";
import Main from "./Components/Main/Main";
import { useState } from "react";
function App() {
  const [classNamemain, setClassNamemain] = useState(true);
  return (
    <div className="App">
      <Header />
      <Landing classNamemain={classNamemain} />
      <Main classNamemain={classNamemain} setClassNamemain={setClassNamemain} />
      <Footer />
    </div>
  );
}

export default App;
